<template>
  <div class="container page" style="background-color: #1b1b1b">
    <van-nav-bar title="출금센터" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
      <template #right>
        <span
          class="nav-right"
          @click="$router.push({ path: '/WithdrawRecord' })"
          >출금기록</span
        >
      </template>
    </van-nav-bar>
    <div class="main" style="padding-top: 24px">
      <div class="withdrawMoney">
        <span>출금금액 (₩)</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field
              v-model="withdraw_money"
              type="number"
              color="#FFF"
              style="background-color: transparent"
            />
          </div>
          <span class="all" @click="allMoeny()">모두</span>
        </div>
        <div style="height: 24px"></div>
        <span>비밀번호를 입력하세요</span>
        <div class="money">
          <div class="moneyNumber">
            <span class="moneyType"></span>
            <van-field
              v-model="withdraw_pwd"
              type="password"
              color="#FFF"
              style="background-color: transparent"
            />
          </div>
        </div>

        <div class="information">
          <div class="description">
            <van-popover v-model="showPopover" trigger="click">
              <div class="popover-body" style="padding: 10px">
                <p>
                  1. 단일 거래 한도: 최소 {{ this.withdrawRole.min }} 최악, 최대
                  {{ this.withdrawRole.max }} 나쁜 사람들
                </p>
                <p>
                  2. 인출 횟수: 같은 날의 최대 인출 횟수
                  {{ this.withdrawRole.num }} 2등실
                </p>
                <p>
                  3. 도착 시간: 일반적으로 도착 시간은 약 5분이며, 가장 빠른
                  도착 시간은 2분입니다.
                </p>
              </div>
              <!-- <template #reference @click="withdrawInfo()">
                <van-icon name="info-o" />
                mô tả hạn ngạch
              </template> -->
            </van-popover>
          </div>

          <div class="balance">
            <span>균형:</span>
            <span class="number">{{ Math.trunc(this.userInfo.money) }}</span>
          </div>
        </div>
      </div>
      <van-button class="withdraw_btn" type="default" @click="doWithdraw()"
        >출금</van-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPopover: false,
      withdraw_money: "",
      withdraw_pwd: "",
      userInfo: {},
      withdrawRole: {},
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getUserWithdrawRole() {
      this.$http({
        method: "get",
        url: "user_get_withdraw_role",
      }).then((res) => {
        if (res.code === 200) {
          this.withdrawRole = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    allMoeny() {
      this.withdraw_money = this.userInfo.money;
    },
    doWithdraw() {
      if (this.withdraw_money <= 0) {
        this.$toast("정확한 금액을 입력해주세요.");
        return false;
      } else {
        this.$http({
          method: "post",
          // 等待添加密码
          data: { money: this.withdraw_money, password: this.withdraw_pwd },
          url: "user_set_withdraw",
        }).then((res) => {
          if (res.code === 200) {
            console.log("res===🚀===>", res);
            // location.reload()
            this.$toast(res.msg);
            this.getUserInfo();
            this.getUserWithdrawRole();

            // 跳转到成功页面
            this.$router.push({ path: "/WithdrawSuccess" });
          } else if (res.code === 401) {
            this.$toast(res.msg);
          }
        });
      }
    },
    withdrawInfo() {
      this.showPopover = true;
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
      this.getUserWithdrawRole();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
.container p {
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #ffcc99;
}
.container .main {
  display: flex;
  flex-direction: column;
  background-color: #1b1b1b;
  height: calc(100% - 50px);
  position: relative;
}
.container .main .withdrawMoney {
  display: flex;
  flex-direction: column;
  color: #ffcc99;
  padding: 0 20px;
  white-space: nowrap;
  font-size: 35px;
  background-color: #1b1b1b;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #ffcc99;
}
.container .main .withdrawMoney .money .moneyNumber {
  font-size: 50px;
  display: flex;
  flex-direction: row;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .money .all {
  color: #ffcc99;
}
.container .main .withdrawMoney .money .moneyNumber .van-cell {
  font-size: 50px;
  padding: 0 !important;
  color: #ffcc99;
}
::v-deep .van-field__control {
  color: #ffcc99 !important;
}
.container .main .withdrawMoney .information {
  padding-bottom: 30px;
}
.container .main .withdrawMoney .information .description {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}
.container .main .withdrawMoney span {
  padding: 10px 0;
}
.container .main .withdrawMoney .information .balance .number {
  color: #ffcc99;
}
.withdraw_btn {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #ffcc99;
  font-size: 30px;
  font-weight: bolder;
  border: 1px solid #ffcc99;
  background-color: transparent;

  // background: linear-gradient(
  //     90deg,#e6c3a1,#7e5678);
}
</style>
