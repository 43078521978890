<template>
  <div class="container page" style="background-color: #1b1b1b">
    <van-nav-bar title="결제 카드 작성" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div
      class="main-box"
      style="
        background-color: transparent;
        border: 1px solid #ffcc99;
        border-radius: 8px;
        margin: 20px 12px;
        padding: 16px;
      "
    >
      <div class="label" style="font-size: 16px; color: #ffcc99">
        결제 카드 정보를 입력하세요
      </div>

      <label
        style="color: #fff; font-size: 16px; font-weight: 700; line-height: 1.8"
        >본명을 입력해 주세요</label
      >
      <div class="custom_input">
        <input v-model="name" placeholder="실명을 입력해 주세요" />
      </div>
      <p style="font-size: 13px">
        계좌의 안전을 위하여 귀하의 실명은 기입된 카드명과 일치하여야 합니다
      </p>

      <label
        style="color: #fff; font-size: 16px; font-weight: 700; line-height: 1.8"
        >은행 계좌 번호</label
      >
      <div class="custom_input">
        <input
          v-model="bankid"
          type="text"
          placeholder="실제 은행 계좌 번호를 입력하세요"
        />
      </div>

      <label
        style="color: #fff; font-size: 16px; font-weight: 700; line-height: 1.8"
        >은행 이름</label
      >
      <div class="custom_input">
        <input
          v-model="bank"
          readonly
          placeholder="은행을 선택하세요."
          @click="showSelectBanks()"
        />
      </div>

      <!-- <van-cell-group>
        <van-field
          v-model="bankid"
          label="은행 카드 번호"
          type="digit"
          placeholder="실제 은행 카드 번호를 입력하세요"
        />
        <van-field
          v-model="bank"
          label="은행 이름"
          readonly
          placeholder="은행을 선택하세요."
          @click="showSelectBanks()"
        />
      </van-cell-group> -->
      <p style="color: #aaa; font-size: 13px">
        사용자님, 자금의 안전을 보장하기 위해 실제 이름을 연결하고 출금
        비밀번호를 설정해 주세요. 이름이 계좌 이름과 일치하지 않으면 자금을
        출금할 수 없습니다.
      </p>
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()"
      >카드 링크 확인</van-button
    >
    <van-popup
      v-model="showBank"
      round
      position="bottom"
      :style="{ height: '35%' }"
    >
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="onCancel"
        confirm-button-text="확인"
        cancel-button-text="취소"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank: false,
      userInfo: {},
      bankid: "",
      bank: "",
      name: "",
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    bindCard() {
      if (this.userInfo.bankid) {
        this.$toast("은행 카드 번호를 입력하세요!");
        return true;
      }
      if (this.name === "" || this.name === null || this.name === undefined) {
        this.$toast.fail("이름을 입력해주세요!");
        return false;
      }
      if (this.bank === "" || this.bank === null || this.bank === undefined) {
        this.$toast.fail("은행을 선택하세요!");
        return false;
      }
      this.$http({
        method: "post",
        data: { bankid: this.bankid, bank: this.bank, realname: this.name },
        url: "user_set_bank",
      }).then((res) => {
        if (res.code === 200) {
          this.$router.push({ path: "/Mine" });
          this.$toast(res.msg);
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    showSelectBanks() {
      this.showBank = true;
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          // this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getBankList() {
      this.$http({
        method: "get",
        url: "sys_get_banks",
      }).then((res) => {
        if (res.code === 200) {
          this.banks = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    onConfirm(value) {
      this.bank = value.text;
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo() {
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.is_bind = true;
          } else {
            this.is_bind = false;
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  },
};
</script>

<style lang="less" scoped>
.custom_input {
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #ffcc99;
  border-radius: 6px;
  height: 68px;
  line-height: 68px;
  padding-left: 24px;
  color: #fff;
}
.custom_input input {
  background-color: transparent;
  border: none;
  outline: none;
}
.custom_input input::placeholder {
  font-size: 24px;
}

@import "../../assets/css/base.css";
.van-cell {
  font-size: 32px;
  line-height: 80px;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox {
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title {
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box {
  background: #fff;
}
.main-box .label {
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
// ::v-deep .van-picker__cancel,
// .van-picker__confirm {
//   padding: 0 20px;
//   font-size: 28px !important;
// }
::v-deep .van-picker__cancel {
  font-size: 28px !important;
  color: #797878;
}

::v-deep .van-picker__confirm {
  font-size: 28px !important;
  color: #797878;
}

::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p {
  padding: 0 20px;
  font-size: 30px;
  color: #aaa;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #ffcc99;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  border: 1px solid #ffcc99;
  background-color: transparent;
  // background: linear-gradient(
  //     90deg,#e6c3a1,#7e5678);
}
</style>
