<template>
  <div
    class="mine page"
    style="background-color: #1b1b1b !important; min-height: 100vh"
  >
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="Success"
      >
        <template #pulling> Scroll down to refresh </template>
        <template #loosing> Scroll down to refresh </template>
        <template #loading> loading </template>
        <div class="header">
          <van-nav-bar class="nav-bar" style="height: 24px">
            <!-- <template #right>
              <van-icon name="setting-o" @click="showSetting()" color="#fff" />
            </template> -->
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image
              round
              class="user_img"
              :src="require('../../assets/999.png')"
            >
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">{{ this.userInfo.username }}</p>
              <p class="login-label">{{ this.userInfo.ip }}</p>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="finance">
            <div class="finance-item" @click="doPay()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">입금</span>
            </div>
            <div class="line"></div>
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">출금</span>
            </div>
          </div>
          <div v-if="this.userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p
                class="flex-1 font-28 font-primary-color"
                style="color: #ffcc99"
              >
                내 지갑
              </p>
              <span class="font-28 font-gray">상세 정보</span>
              <van-icon
                class="font-gray"
                style="font-size: 28px"
                name="arrow"
              />
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis" style="color: #ffcc99">
                {{ Math.trunc(this.userInfo.money) }}
              </p>
              <span class="font-28 font-gray">잔액</span>
              <div class="refresh-btn" @click="refresh()">
                <van-icon name="replay" />
              </div>
            </div>
          </div>
          <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <div
              class="menu-item"
              @click="$router.push({ path: '/SetLoginPassword' })"
            >
              <van-image class="menu-item-icon" src="img/mine/pwd1.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">로그인 비번 설정</span>
            </div>
            <div
              class="menu-item"
              @click="$router.push({ path: '/SetPayPassword' })"
            >
              <van-image class="menu-item-icon" src="img/mine/pwd2.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">결제 비번 설정</span>
            </div>

            <div
              class="menu-item"
              @click="$router.push({ path: '/Personalreport' })"
            >
              <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">손익상태</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/Setbank' })">
              <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">은행 계좌</span>
            </div>
            <div
              class="menu-item"
              @click="$router.push({ path: '/GameRecord' })"
            >
              <van-image class="menu-item-icon" src="img/mine/youxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">마이 투표</span>
            </div>
            <div
              class="menu-item"
              @click="$router.push({ path: '/Infomation' })"
            >
              <van-image class="menu-item-icon" src="img/mine/user.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">개인 센터</span>
            </div>
            <div class="menu-item" @click="toNotice()">
              <van-image class="menu-item-icon" src="img/mine/gonggao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">공지</span>
            </div>

            <div class="menu-item" @click="loginout()">
              <van-image class="menu-item-icon" src="img/mine/logout.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">로그아웃</span>
            </div>

            <!-- <div class="menu-item" @click="toService()">
              <van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">온라인 서비스</span>
            </div> -->
          </div>
        </div>
      </van-pull-refresh>
    </div>
    <van-popup
      v-model="show"
      round
      @closed="form.amount = ''"
      :close-on-click-overlay="!payLoading"
    >
      <div class="pay-content" style="background-color: #1b1b1b !important">
        <div style="width: 100%">
          <label
            style="
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.8;
            "
            >카드 번호</label
          >
          <div class="custom_input">
            <input
              v-model="form.bank_number"
              readonly
              placeholder="카드 번호를 입력하세요"
            />
          </div>

          <label
            style="
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.8;
            "
            >이름</label
          >
          <div class="custom_input">
            <input
              v-model="form.bank_name"
              readonly
              placeholder="카드 번호를 입력하세요"
            />
          </div>

          <label
            style="
              color: #fff;
              font-size: 16px;
              font-weight: 700;
              line-height: 1.8;
            "
            >돈</label
          >
          <div class="custom_input">
            <input
              v-model="form.amount"
              :readonly="payLoading"
              placeholder="입금 금액을 입력하세요"
            />
          </div>

          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <van-button
              round
              @click="pay"
              class="button c_btn"
              :disabled="!form.amount"
              :loading="payLoading"
              loading-text="charging"
              >충전</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      show: false,
      payLoading: false,
      form: {
        bank_number: localStorage.getItem("bank_number"),
        bank_name: localStorage.getItem("bank_name"),
        amount: "",
      },
      menu_top: 40,
      isLoading: false,
    };
  },
  methods: {
    loginout() {
      localStorage.clear();
      this.$router.push({ path: "/Login" });
    },
    pay() {
      if (!this.form.amount) return this.$toast("금액을 입력하세요");
      this.payLoading = true;
      this.$http({
        method: "post",
        data: this.form,
        url: "recharge_apply",
      })
        .then((res) => {
          if (res.code === 200) {
            this.show = false;
            this.$toast("검토를 위해 제출됨");
          } else {
            this.show = false;
            this.$toast("고객 서비스 부서에 문의하세요");
          }
        })
        .catch(() => {
          this.show = false;
          this.$toast("고객 서비스 부서에 문의하세요");
        });
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.$toast("로딩 중");
        } else {
          this.$router.push({ path: "/Login" });
        }
      }, 500);
    },
    exit() {
      this.$toast("작업 목록을 작성하고 입력하세요");
    },
    showSetting() {
      if (localStorage.getItem("token")) {
        this.$router.push({ path: "/Setting" });
      } else {
        this.$router.push({ path: "/Login" });
      }
    },
    toNotice() {
      if (localStorage.getItem("token")) {
        this.$router.push({ path: "/Notice" });
      } else {
        this.$router.push({ path: "/Login" });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.getUserInfo();
          this.$toast("성공");
        } else {
          this.$router.push({ path: "/Login" });
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem("token")) {
        this.$router.push({ path: "/Infomation" });
      } else {
        this.$router.push({ path: "/Login" });
      }
    },
    doPay() {
      if (localStorage.getItem("token")) {
        // this.show = true;
        this.$toast("담당자에게 문의해주세요");
      }
      // else {
      //   this.$router.push({ path: "/Login" });
      // }
      // this.$toast("Vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền");
      // return false;
    },
    doWithdrawal() {
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail("결제 카드를 설정하세요!");
        }
      });
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail("CSKH에 도움을 요청하세요!");
      }
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log("userInfo===🚀===>", this.userInfo);
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("오프라인 계정");
            localStorage.clear();
            this.$router.push({ path: "/Login" });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.getUserInfo();
    } else {
      this.userInfo.username = "로그인/회원가입";
      this.userInfo.ip = "로그인하여 더 많은 서비스를 이용하세요!";
      this.userInfo.header_img = "img/mine/avatar.png";
    }
  },
};
</script>

<style lang="less" scoped>
.custom_input {
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #ffcc99;
  border-radius: 6px;
  height: 68px;
  line-height: 68px;
  padding-left: 24px;
  color: #fff;
}
.custom_input input {
  background-color: transparent;
  border: none;
  outline: none;
}

.pay-content {
  width: 80vw;
  min-height: 530px;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }
  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1b1b1b !important;
}
.mine {
  position: relative;
  bottom: 10px;
  background: #1b1b1b;
}
.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.nav-bar {
  background-color: #1b1b1b !important;
}
.mine .header {
  background-color: #1b1b1b !important;
  padding-bottom: 100px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}
.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;
}
.mine .user_img {
  height: 130px;
  width: 130px;
  border: 1px solid #ffcc99;
}
::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}
::v-deep .van-image__error-icon {
  font-size: 70px;
}
.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}
.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}
.mine .header .user-wrapper .login-content .login-label {
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0, 0%, 100%, 0.6);
}
.mine .page-bg {
  height: 500px;
  background-color: #1b1b1b;
}
.mine .content {
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #1b1b1b;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #1b1b1b;
  border-radius: 15px;
  border: 1px solid #ffcc99;
  box-shadow: 0 1.5px 1px 0 #ffcc99;
}
.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}
.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.mine .wrapper .content .finance .finance-item .text {
  margin-left: 30px;
  font-size: 30px;
  color: #ffcc99;
  font-weight: 500;
}
.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
  color: #ffcc99;
}
.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #1b1b1b;
  border-radius: 15px;
  border: 1px solid #ffcc99;
  box-shadow: 0 1.5px 1px 0 #ffcc99;
}
.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}
.mine .wrapper .content .menu .menu-item .menu-item-label {
  font-size: 24px;
  color: #ffcc99;
  font-weight: 500;
}
.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}
.mine .wrapper .content .wallet {
  margin-top: 80px;
  padding: 0 30px;
  background-color: #1b1b1b;
  border-radius: 15px;
  border: 1px solid #ffcc99;
  box-shadow: 0 1.5px 1px 0 #ffcc99;
}
.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 100px;
}
.mine .wrapper .content .wallet .font-primary-color {
  color: #000;
}
.font-gray {
  color: #868686;
}
.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 150px;
}
.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 60px;
  color: #7e5678;
  font-weight: 700;
}
.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #ffcc99;
}
</style>
