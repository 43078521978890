export default {
  login: {
    title: `로그인`,
    enterName: `사용자 이름을 입력하십시오`,
    enterPwd: `비밀번호를 입력하십시오`,
    forgotPwd: `비밀번호를 잊으셨습니까?`,
    tipSuccess: `로그인 성공`,
    tipLinkTo: `계정이 없으신가요? 지금 등록하세요.`,
  },
};
