<template>
  <div class="page_bg">
    <!-- <img class="bg-img" src="../../../public/img/login/login-bg.jpg" /> -->
    <van-nav-bar class="nav-bar" title="회원가입">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>

    <div
      style="
        background-color: rgba(0, 0, 0, 0.7);
        width: 90%;
        margin: 10px auto;
        margin-top: 140px;
        border-radius: 12px;
        padding: 20px 24px;
      "
    >
      <label
        style="
          color: #ffcc99;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.8;
        "
        >계정</label
      >
      <div class="custom_input">
        <input
          v-model="username"
          type="text"
          placeholder="아이디를 입력하세요"
        />
      </div>

      <label
        style="
          color: #ffcc99;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.8;
        "
        >비밀번호</label
      >
      <div class="custom_input">
        <input
          v-model="password"
          :type="passwordType"
          placeholder="비밀번호를 입력해주세요"
        />
        <van-icon
          color="#aaa"
          :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
          @click="switchPasswordType"
        />
      </div>

      <label
        style="
          color: #ffcc99;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.8;
        "
        >비밀번호 확인</label
      >
      <div class="custom_input">
        <input
          v-model="password2"
          :type="passwordType"
          placeholder="비밀번호 확인"
        />
        <van-icon
          color="#aaa"
          :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'"
          @click="switchPasswordType"
        />
      </div>

      <label
        style="
          color: #ffcc99;
          font-size: 16px;
          font-weight: 700;
          line-height: 1.8;
        "
        >초대코드</label
      >
      <div class="custom_input">
        <input
          v-model="code"
          type="text"
          placeholder="초대 코드를 입력하세요"
        />
      </div>

      <div style="display: flex; align-items: center">
        <div class="custom_input">
          <input v-model="vcode" type="text" placeholder="인증코드" />
        </div>
        <div
          class="custom_input"
          style="
            min-width: 70px;
            margin-left: auto;
            color: #ffcc99;
            padding: 0;
            text-align: center;
          "
          @click="genVCode()"
        >
          {{ curCode }}
        </div>
      </div>

      <!-- <div
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
      >
        <div style="font-size: 13px; color: #aaa">
          {{ curLang.forgotPwd }}
        </div>
        <div @click="toRegister()" style="font-size: 13px; color: #ffcc99">
          회원가입
        </div>
      </div> -->

      <!-- <div class="agreement">
        <van-checkbox v-model="checked" />
        <span class="agreement-text" 
          >계좌 개설 약관’의 조건을 이해하고 동의합니다.</span
        >
      </div> -->

      <div
        style="
          width: 80%;
          margin: 24px auto;
          border: 1px solid #ffcc99;
          border-radius: 22px;
          padding: 8px 0;
          text-align: center;
          color: #ffcc99;
        "
        @click="doRegister()"
      >
        회원가입
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // checked: true,
      username: "",
      code: "",
      password: "",
      password2: "",
      curCode: "인증코드", // 当前生成码
      vcode: "", // 验证码
      passwordType: "password",
    };
  },
  methods: {
    // 生成四个随机整数
    genVCode() {
      let randomNumber = "";
      for (let i = 0; i < 4; i++) {
        randomNumber += Math.floor(Math.random() * 10); // 生成0到9之间的随机数字
      }
      this.curCode = randomNumber;
    },
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      return window.history.back();
    },
    doRegister() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast.fail("사용자 이름을 입력하세요!");
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast.fail("비밀번호를 입력하세요!");
        return false;
      }
      if (
        this.password2 === "" ||
        this.password2 === null ||
        this.password2 === undefined
      ) {
        this.$toast.fail("비밀번호를 입력하세요!");
        return false;
      }
      if (this.password !== this.password2) {
        this.$toast.fail("두 번 입력한 비밀번호가 일치하지 않습니다.");
        return false;
      }
      if (this.code === "" || this.code === null || this.code === undefined) {
        this.$toast.fail("초대 코드를 입력하세요!");
        return false;
      }

      if (
        this.vcode === "" ||
        this.vcode === null ||
        this.vcode === undefined
      ) {
        this.$toast.fail("인증번호를 입력해주세요");
        return false;
      }
      if (this.vcode !== this.curCode) {
        this.$toast(`인증코드 오류`);
        return false;
      }
      // if (!this.checked) {
      //   this.$toast.fail("아래의 계좌 개설 약관을 확인하세요!");
      //   return false;
      // }
      this.$http({
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          code: this.code,
        },
        url: "member_register",
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("token", res.data);
          this.$router.push("Mine");
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang="less" scoped>
.page_bg {
  background-image: url(../../../public/img/login/login-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
}

.custom_input {
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 6px;
  height: 80px;
  line-height: 80px;
  padding-left: 24px;
  color: #fff;
  margin-bottom: 40px;
}
.custom_input input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 80%;
}
.custom_input input::placeholder {
  font-size: 24px;
}

@import "../../assets/css/base.css";

// .register {
//   height: 100%;
// }
// .bg-container .bg-wrapper .register .nav-bar {
//   background: 0 0;
// }
// .register .wrapper {
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
// }
// .logo-container {
//   margin: 0 auto;
//   width: 45%;
// }
// .logo-container .logo-wrapper {
//   position: relative;
//   padding-bottom: 62.5%;
// }
// .logo-container .logo-wrapper .logo-img {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   -o-object-fit: contain;
//   object-fit: contain;
// }
// .register .wrapper .title {
//   line-height: 100px;
//   text-align: center;
//   font-size: 45px;
//   font-weight: 700;
//   color: #fff;
//   letter-spacing: 5px;
// }
// .register .wrapper .loginForm {
//   padding: 60px;
// }
// .register .wrapper .loginForm .input {
//   padding: 10px 20px;
//   margin-top: 35px;
//   border-radius: 50px;
//   text-align: center;
//   line-height: 70px;
//   font-size: 30px;
//   color: #4e4e4e;
// }
// ::v-deep .van-field__right-icon .van-icon {
//   font-size: 50px;
// }
// ::v-deep .van-icon {
//   font-size: 50px;
// }
// .register .wrapper .loginForm .reset-text {
//   margin: 30px 15px;
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
// }
// .register .wrapper .loginForm .reset-text span {
//   color: #fff;
//   font-size: 25px;
//   font-weight: 500;
//   line-height: 15px;
// }
// .register .wrapper .loginForm .register-text {
//   margin: 10px 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .register .wrapper .loginForm .register-text span {
//   color: #fff;
//   font-size: 25px;
//   font-weight: 500;
//   line-height: 20px;
// }
// .register .wrapper .loginForm .login-btn {
//   margin-top: 30px;
//   width: 100%;
//   height: 100px;
//   border-radius: 50px;
//   color: #fff;
//   background-color: #7e5678;
//   font-size: 30px;
//   font-weight: bolder;
//   border: none;
// }
// .register .wrapper .loginForm .agreement {
//   margin-top: 30px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .register .wrapper .loginForm .agreement .agreement-text {
//   margin-left: 10px;
//   font-size: 25px;
//   color: #fff;
//   font-weight: 500;
//   line-height: 30px;
// }
// ::v-deep .agreement .van-icon {
//   font-size: 30px;
// }
// ::v-deep .agreement .van-checkbox__icon {
//   font-size: 38px;
// }
// ::v-deep .agreement .van-checkbox__icon--checked .van-icon {
//   color: #fff;
//   border-color: rgb(126, 86, 120);
//   background-color: rgb(126, 86, 120);
// }
</style>
