<template>
  <div class="container page" style="background-color: #1b1b1b;min-height: 100vh;" >
    <van-nav-bar title="결제 카드 정보" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="wrapper">
      <div class="add-card" @click="toBindCard()" v-if="!is_bind">
        <van-icon name="plus" />
        <span>결제 카드를 추가하세요</span>
      </div>
      <div class="bank" v-else>
          <div class="info">
            <div class="row-content">은행 이름:{{this.bankInfo.bankinfo}}</div>
            <div class="row-content">이름 및 성:{{this.bankInfo.realname}}</div>
            <div class="row-content">은행 카드 번호:{{this.bankInfo.bankid}}</div>
          </div>
      </div>
      <div class="tips">주의: 변경이 필요하신 경우 온라인 고객 센터로 
문의 부탁드립니다</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_bind:false,
      bankInfo:{},
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
            this.bankInfo = res.data.info;
            console.log(`??`, this.bankInfo);
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    toBindCard() {
      // if (!this.userInfo.name) {
      //   this.$router.push("Setname");
      //   this.$toast("이름을 지정하고 은행 카드에 연결하세요!");
      //   return true;
      // }else 
      if(!this.userInfo.paypassword){
        this.$router.push("SetPayPassword");
        this.$toast("은행 카드를 연결하기 전에 출금 비밀번호를 설정하세요!");
        return true;
      } else {
        this.$router.push({path:'/BindCard'})
      }
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}

.container p{
  padding: 0 15px;
  margin-top: 15px;
  font-size: 30px;
  color: #dc2037;
}
.manage-card .wrapper{
  height: calc(100% - 10px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.wrapper .add-card{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color:#FFF;
  border:1px solid #FFCC99;
  margin:20px ;
  height: 250px;
}
.wrapper .add-card span{
  margin-left: 10px;
  font-size: 30px;
}
.wrapper .tips {
  margin: 15px 15px;
  font-size: 25px;
  color: #979799;
}
.wrapper .bank .info {
  margin-left: 20px;
  flex: 1;
  color: #FFCC99;
}
.wrapper .bank .info .row-content{
  margin: 30px;
  line-height: 20px;
  font-size: 30px;
}
</style>
