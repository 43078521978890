<template>
  <div
    style="
      min-height: 100vh;
      background-position: 0 0;
      background-repeat: no-repeat;
      background-size: 100% 60vh;
    "
    :style="{ backgroundImage: `url(${xuanfeidata.img_url[0]})` }"
  >
    <van-nav-bar title="Choose a wife" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>

    <div
      style="
        margin-top: 320px;
        border-radius: 18px 18px 0 0;
        background-color: rgba(0, 0, 0, 0.95);
        padding-bottom: 100px;
      "
    >
      <div
        style="
          background-color: #ffcc99;
          line-height: 2.4;
          border-radius: 16px 16px 0 0;
          text-align: center;
          font-size: larger;
          color: #fff;
        "
      >
        {{ xuanfeidata.xuanfei_name }}
      </div>
      <div
        style="padding: 0 20px; color: #fff; font-size: 13px; padding-top: 12px"
      >
        <div>
          {{
            xuanfeidata.nl +
            `세 ` +
            xuanfeidata.shengao +
            ` ` +
            xuanfeidata.chicun +
            `컵 사이즈 `
          }}
          {{ xuanfeidata.city + ` ` + xuanfeidata.area }}
        </div>
        <div
          style="
            font-size: 0.75rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ecca6f;
            padding: 1.0625rem 1.25rem;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAACxUlEQVRIia2WW4iNURTHf3N4wvfgAYNRhxL5xmXcH1yTNKIpDx6UkAlPzquM2zCYKdL3JMmUSbnlDXnQ5BqJUcwpCk2NS24jc5qhQbRm/l/z9e1zxjkz83/Zt7X2f6+111p7F7U37yQLlgPLgMXAH+AucAe4FRf1/IBMOpUEVgNLgPFAM/AIuOT5QUdUPk44D6gHSt0zdKMJ2A48jsydBiodyR7YYas8P6jLRrgNOKX+FeAC8EbjicBGoELjCllgFk+R3DHgGdAOjAYWAQckf9Xzg7VRwqURd5ngfeesPVgF3IjN1Xl+sMuRBHP1GHOrXH3e84MNIeEHoBhYATQCw4BO6ZUBNcAe4CkwB7gG2Ga7PT846jC5xK+BScDcBLBOZA0i2wJ8B9ZIfpQCIqnxE2Cs3PZfMmG92uNm4XWgHBgO/AK6tDgVeKm+rXU42yhK80EmnWoFShKRiOzUXRr2R8jIRVYg7pl4Qqf/K91itU2DQBDHz5DwvUWrFh+qzVoNBohkSNiofSwdXikSV1oEDjKhVa0fRnhCExfVLgC+AdWOSj+RSacOAUOAWiNsAc4B44CTilQzf/IgkS1UDv8OCVHuGfEO4LDKU4ujXTjZbOCBhuWeH3SFhMY+H2jV3eWb0DmRSadKVSQMmzw/uImCJsRnYBbwCbDaeDDXZnmQWfl7ruFmzw8awrVETLZNpJYqe1UACiWbHnm+Kj0/OBtdjxOiQm5KX/W87HMkciCTTpXpiTJs9fzgTFwyGyGydKbIq2Vtn8ikUyWRCmVurM8mP9SZ6cU7wAde6D7t9T7iSPXC7v42cDnuxihy/WmiKFG02XNUlYXUojvQV6LR0Y4hl0ujeCtL25SjVbH1EYAl9wRHMwv6cmkUX4AZCoga5W33x0hWFTkaA7AwhN3pNN1VLWD1Md8D94vQ8FF/GnvbrD6OLEgb+Ad3n7rHyr3uYgAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            background-size: 0.875rem 0.8125rem;
            background-position: 0 center;
          "
        >
          약속 프로세스
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
          "
        >
          <div style="text-align: center">
            <img
              src="../../assets/choose_0.png"
              style="width: 48px; height: 48px"
            />
            <div style="color: #ffcc99; font-size: 12px">온라인 선택</div>
          </div>
          <div style="text-align: center">
            <img
              src="../../assets/choose_1.png"
              style="width: 48px; height: 48px"
            />
            <div style="color: #ffcc99; font-size: 12px">주문 예약</div>
          </div>
          <div style="text-align: center">
            <img
              src="../../assets/choose_2.png"
              style="width: 48px; height: 48px"
            />
            <div style="color: #ffcc99; font-size: 12px">출장 서비스</div>
          </div>
          <div style="text-align: center">
            <img
              src="../../assets/choose_3.png"
              style="width: 48px; height: 48px"
            />
            <div style="color: #ffcc99; font-size: 12px">서비스 평가</div>
          </div>
        </div>

        <div
          style="
            font-size: 0.75rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ecca6f;
            padding: 1.0625rem 1.25rem;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAACxUlEQVRIia2WW4iNURTHf3N4wvfgAYNRhxL5xmXcH1yTNKIpDx6UkAlPzquM2zCYKdL3JMmUSbnlDXnQ5BqJUcwpCk2NS24jc5qhQbRm/l/z9e1zxjkz83/Zt7X2f6+111p7F7U37yQLlgPLgMXAH+AucAe4FRf1/IBMOpUEVgNLgPFAM/AIuOT5QUdUPk44D6gHSt0zdKMJ2A48jsydBiodyR7YYas8P6jLRrgNOKX+FeAC8EbjicBGoELjCllgFk+R3DHgGdAOjAYWAQckf9Xzg7VRwqURd5ngfeesPVgF3IjN1Xl+sMuRBHP1GHOrXH3e84MNIeEHoBhYATQCw4BO6ZUBNcAe4CkwB7gG2Ga7PT846jC5xK+BScDcBLBOZA0i2wJ8B9ZIfpQCIqnxE2Cs3PZfMmG92uNm4XWgHBgO/AK6tDgVeKm+rXU42yhK80EmnWoFShKRiOzUXRr2R8jIRVYg7pl4Qqf/K91itU2DQBDHz5DwvUWrFh+qzVoNBohkSNiofSwdXikSV1oEDjKhVa0fRnhCExfVLgC+AdWOSj+RSacOAUOAWiNsAc4B44CTilQzf/IgkS1UDv8OCVHuGfEO4LDKU4ujXTjZbOCBhuWeH3SFhMY+H2jV3eWb0DmRSadKVSQMmzw/uImCJsRnYBbwCbDaeDDXZnmQWfl7ruFmzw8awrVETLZNpJYqe1UACiWbHnm+Kj0/OBtdjxOiQm5KX/W87HMkciCTTpXpiTJs9fzgTFwyGyGydKbIq2Vtn8ikUyWRCmVurM8mP9SZ6cU7wAde6D7t9T7iSPXC7v42cDnuxihy/WmiKFG02XNUlYXUojvQV6LR0Y4hl0ujeCtL25SjVbH1EYAl9wRHMwv6cmkUX4AZCoga5W33x0hWFTkaA7AwhN3pNN1VLWD1Md8D94vQ8FF/GnvbrD6OLEgb+Ad3n7rHyr3uYgAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            background-size: 0.875rem 0.8125rem;
            background-position: 0 center;
          "
        >
          여성 정보
        </div>

        <div style="display: flex; align-items: center; padding-left: 20px">
          <div style="flex: 0 0 25%">
            <p style="font-size: 0.875rem; color: #efd6a6">
              {{ xuanfeidata.nl }}세
            </p>
            <p style="font-size: 0.75rem; color: #cccccc; font-weight: 400">
              나이
            </p>
          </div>
          <div style="flex: 0 0 50%">
            <p style="font-size: 0.875rem; color: #efd6a6">
              {{ xuanfeidata.shengao }}센티미터
            </p>
            <p style="font-size: 0.75rem; color: #cccccc; font-weight: 400">
              키
            </p>
          </div>
          <div style="flex: 0 0 25%">
            <p style="font-size: 0.875rem; color: #efd6a6">
              {{ xuanfeidata.tizhong }}
            </p>
            <p style="font-size: 0.75rem; color: #cccccc; font-weight: 400">
              몸무게
            </p>
          </div>
        </div>
        <div style="display: flex; align-items: center; padding-left: 20px">
          <div style="flex: 0 0 25%">
            <p style="font-size: 0.875rem; color: #efd6a6">
              {{ xuanfeidata.chicun }}
            </p>
            <p style="font-size: 0.75rem; color: #cccccc; font-weight: 400">
              컵 사이즈
            </p>
          </div>
          <div style="flex: 0 0 50%">
            <p style="font-size: 0.875rem; color: #efd6a6">
              {{ xuanfeidata.city }}
            </p>
            <p style="font-size: 0.75rem; color: #cccccc; font-weight: 400">
              서비스 도시
            </p>
          </div>
          <div style="flex: 0 0 25%">
            <p style="font-size: 0.875rem; color: #efd6a6">
              {{ xuanfeidata.area }}
            </p>
            <p style="font-size: 0.75rem; color: #cccccc; font-weight: 400">
              서비스 지역
            </p>
          </div>
        </div>

        <hr style="border-color: #333333; margin-bottom: 0.625rem" />
        <!-- <p style="color: #ffcc99">{{ xuanfeidata.xiangmu }}</p>
        <p style="color: #ccc">서비스 항목</p>
        <hr style="border-color: #333333; margin-bottom: 0.625rem" /> -->

        <div
          style="
            font-size: 0.75rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ecca6f;
            padding: 1.0625rem 1.25rem;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAACxUlEQVRIia2WW4iNURTHf3N4wvfgAYNRhxL5xmXcH1yTNKIpDx6UkAlPzquM2zCYKdL3JMmUSbnlDXnQ5BqJUcwpCk2NS24jc5qhQbRm/l/z9e1zxjkz83/Zt7X2f6+111p7F7U37yQLlgPLgMXAH+AucAe4FRf1/IBMOpUEVgNLgPFAM/AIuOT5QUdUPk44D6gHSt0zdKMJ2A48jsydBiodyR7YYas8P6jLRrgNOKX+FeAC8EbjicBGoELjCllgFk+R3DHgGdAOjAYWAQckf9Xzg7VRwqURd5ngfeesPVgF3IjN1Xl+sMuRBHP1GHOrXH3e84MNIeEHoBhYATQCw4BO6ZUBNcAe4CkwB7gG2Ga7PT846jC5xK+BScDcBLBOZA0i2wJ8B9ZIfpQCIqnxE2Cs3PZfMmG92uNm4XWgHBgO/AK6tDgVeKm+rXU42yhK80EmnWoFShKRiOzUXRr2R8jIRVYg7pl4Qqf/K91itU2DQBDHz5DwvUWrFh+qzVoNBohkSNiofSwdXikSV1oEDjKhVa0fRnhCExfVLgC+AdWOSj+RSacOAUOAWiNsAc4B44CTilQzf/IgkS1UDv8OCVHuGfEO4LDKU4ujXTjZbOCBhuWeH3SFhMY+H2jV3eWb0DmRSadKVSQMmzw/uImCJsRnYBbwCbDaeDDXZnmQWfl7ruFmzw8awrVETLZNpJYqe1UACiWbHnm+Kj0/OBtdjxOiQm5KX/W87HMkciCTTpXpiTJs9fzgTFwyGyGydKbIq2Vtn8ikUyWRCmVurM8mP9SZ6cU7wAde6D7t9T7iSPXC7v42cDnuxihy/WmiKFG02XNUlYXUojvQV6LR0Y4hl0ujeCtL25SjVbH1EYAl9wRHMwv6cmkUX4AZCoga5W33x0hWFTkaA7AwhN3pNN1VLWD1Md8D94vQ8FF/GnvbrD6OLEgb+Ad3n7rHyr3uYgAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            background-size: 0.875rem 0.8125rem;
            background-position: 0 center;
          "
        >
          여성 정보 자세히
        </div>

        <div style="display: flex; align-items: center; flex-wrap: wrap">
          <div
            v-for="(v, k) in xuanfeidata.img_url"
            :key="k"
            style="flex: 0 0 50%"
          >
            <div
              style="
                margin: 6px;
                background-position: 0 0;
                background-repeat: no-repeat;
                background-size: 100%;
                height: 260px;
              "
              :style="{ backgroundImage: `url(${v})` }"
            ></div>
          </div>
        </div>

        <div
          style="
            font-size: 0.75rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #ecca6f;
            padding: 1.0625rem 1.25rem;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAZCAYAAAAiwE4nAAACxUlEQVRIia2WW4iNURTHf3N4wvfgAYNRhxL5xmXcH1yTNKIpDx6UkAlPzquM2zCYKdL3JMmUSbnlDXnQ5BqJUcwpCk2NS24jc5qhQbRm/l/z9e1zxjkz83/Zt7X2f6+111p7F7U37yQLlgPLgMXAH+AucAe4FRf1/IBMOpUEVgNLgPFAM/AIuOT5QUdUPk44D6gHSt0zdKMJ2A48jsydBiodyR7YYas8P6jLRrgNOKX+FeAC8EbjicBGoELjCllgFk+R3DHgGdAOjAYWAQckf9Xzg7VRwqURd5ngfeesPVgF3IjN1Xl+sMuRBHP1GHOrXH3e84MNIeEHoBhYATQCw4BO6ZUBNcAe4CkwB7gG2Ga7PT846jC5xK+BScDcBLBOZA0i2wJ8B9ZIfpQCIqnxE2Cs3PZfMmG92uNm4XWgHBgO/AK6tDgVeKm+rXU42yhK80EmnWoFShKRiOzUXRr2R8jIRVYg7pl4Qqf/K91itU2DQBDHz5DwvUWrFh+qzVoNBohkSNiofSwdXikSV1oEDjKhVa0fRnhCExfVLgC+AdWOSj+RSacOAUOAWiNsAc4B44CTilQzf/IgkS1UDv8OCVHuGfEO4LDKU4ujXTjZbOCBhuWeH3SFhMY+H2jV3eWb0DmRSadKVSQMmzw/uImCJsRnYBbwCbDaeDDXZnmQWfl7ruFmzw8awrVETLZNpJYqe1UACiWbHnm+Kj0/OBtdjxOiQm5KX/W87HMkciCTTpXpiTJs9fzgTFwyGyGydKbIq2Vtn8ikUyWRCmVurM8mP9SZ6cU7wAde6D7t9T7iSPXC7v42cDnuxihy/WmiKFG02XNUlYXUojvQV6LR0Y4hl0ujeCtL25SjVbH1EYAl9wRHMwv6cmkUX4AZCoga5W33x0hWFTkaA7AwhN3pNN1VLWD1Md8D94vQ8FF/GnvbrD6OLEgb+Ad3n7rHyr3uYgAAAABJRU5ErkJggg==);
            background-repeat: no-repeat;
            background-size: 0.875rem 0.8125rem;
            background-position: 0 center;
          "
        >
          주의 사항
        </div>

        <div
          style="
            height: 2.3125rem;
            line-height: 2.3125rem;
            border-radius: 0.5625rem;
            background: #ceb585;
            text-align: center;
            font-size: 1rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #000000;
          "
          @click="yuyue"
        >
          그녀와 약속하기를 클릭하세요
        </div>
      </div>
    </div>

    <!-- <div class="box" style="margin-top: 0">
      <p class="name">{{ xuanfeidata.text }}</p>
      <p class="title">{{ this.xuanfeidata.vo_title }}</p>
      <van-image
        width="98%"
        fit="contain"
        height="100%"
        v-for="(v, k) in xuanfeidata.img_url"
        :key="k"
        :src="v"
      />
      <van-image
        width="98%"
        fit="contain"
        height="100%"
        :key="k"
        :src="xuanfeidata.img"
      />
      <van-button round @click="yuyue" class="button c_btn">reserve</van-button>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      xuanfeidata: null,
    };
  },
  methods: {
    back() {
      // this.$router.push({ path: 'list?id=' + this.$route.query.adsid + '&name=' + this.$route.query.name });
      this.$router.push({ path: "Choose" });
    },
    getxuanfeidata() {
      this.$http({
        method: "get",
        url: "xuanfeidata",
        data: { id: this.$route.query.id },
      }).then((res) => {
        console.log(`res:`, res.data);
        this.xuanfeidata = res.data;
      });
    },
    yuyue() {
      this.$toast("컨설턴트 또는 접수처에 문의하세요.");
    },
  },
  created() {
    this.getxuanfeidata();
    // this.xuanfeidata = this.list.find(
    //   (item) => item.id == this.$route.query.id
    // );
  },
};
</script>

<style>
.nav-bar {
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.c_btn {
  color: #ffcc99;
  border: 1px solid #ffcc99;
  background-color: transparent;
}
.container {
  display: inline-block;
}
.box {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  padding-bottom: 6.25rem;
  height: 100%;
  background-color: transparent;
  margin-top: 600px;
}
.name {
  font-size: 1.125rem;
  color: #ffcc99;
  transform: translateY(20px);
}
.title {
  font-size: 0.625rem;
}
.button {
  width: 10rem;
  height: 2.5rem;
  font-size: 0.9375rem;
  margin-top: 0.625rem;
}
</style>
