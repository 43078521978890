var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container page",staticStyle:{"background-color":"#1b1b1b","min-height":"100vh"}},[_c('van-nav-bar',{staticClass:"nav-bar",attrs:{"title":"기본 정보"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","color":"#fff"},on:{"click":function($event){return _vm.back()}}})]},proxy:true}])}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.openHerderImg()}}},[_c('div',{staticClass:"left",staticStyle:{"color":"#fff"}},[_vm._v("대표성")]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require('../../assets/999.png')}}),_c('van-icon',{attrs:{"name":"arrow","color":"#FFF"}})],1)]),_c('van-popup',{style:({ height: '50%' }),attrs:{"position":"bottom","round":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"avatarbox"},[_c('div',{staticClass:"title van-hairline--bottom"},[_c('van-icon',{attrs:{"name":"cross"},on:{"click":function($event){_vm.show = false}}}),_c('div',{staticClass:"text"},[_vm._v("아바타를 선택하세요")]),_c('div',{staticClass:"btnok",on:{"click":function($event){return _vm.check()}}},[_vm._v("물론입니다")])],1),_c('div',{staticClass:"content"},_vm._l((30),function(item,index){return _c('van-image',{key:index,class:{
              choose:
                _vm.isActive ===
                'https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' +
                  item +
                  '.png',
            },attrs:{"round":"","src":require(`../../assets/${index + 1}.jpg`)},on:{"click":function($event){return _vm.select_header_img(
                'https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' +
                  item +
                  '.png'
              )}}})}),1)])]),_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.toSetSex()}}},[_c('div',{staticClass:"left",staticStyle:{"color":"#fff"}},[_vm._v("성별")]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(this.userInfo.sex !== "0" ? this.userInfo.sex === "1" ? "남자" : "여자" : "알 수 없음"))]),_c('van-icon',{attrs:{"name":"arrow","color":"#FFF"}})],1)]),_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.toSetBank()}}},[_c('div',{staticClass:"left",staticStyle:{"color":"#fff"}},[_vm._v("제약 정보")]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(this.isBank ? "강제" : "결석"))]),_c('van-icon',{attrs:{"name":"arrow","color":"#FFF"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }