<template>
  <div style="background-color: #1b1b1b; min-height: 100vh">
    <van-nav-bar title="처리 중입니다" class="nav-bar">
      <template #left>
        <van-icon
          name="arrow-left"
          color="#fff"
          @click="$router.push({ path: `/Mine` })"
        />
      </template>
    </van-nav-bar>

    <div
      style="
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 100px;
        flex-direction: column;
      "
    >
      <img src="../../../public/img/mine/wsuccess.svg" />
      <div style="font-size: larger; font-weight: 900; margin-top: 60px;color:#FFCC99;">
        처리 중입니다
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {},
};
</script>
