<template>
  <div class="container page" style="background-color: #1b1b1b;min-height: 100vh;" >
    <van-nav-bar title="로그인 비밀번호 변경" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
      <template #right>
        <span class="nav-right" @click="save()">도움</span>
      </template>
    </van-nav-bar>

    <div style="padding:20px;" >
      <label
        style="color: #fff; font-size: 16px; font-weight: 700; line-height: 1.8"
        >비밀번호</label
      >
      <div class="custom_input">
        <input
          v-model="old_password"
          type="password"
          placeholder="비밀번호"
        />
      </div>
      <label
        style="color: #fff; font-size: 16px; font-weight: 700; line-height: 1.8"
        >새 비밀번호</label
      >
      <div class="custom_input">
        <input
          v-model="o_new_password"
          type="password"
          placeholder="새 비밀번호"
        />
      </div>
      <label
        style="color: #fff; font-size: 16px; font-weight: 700; line-height: 1.8"
        >새 비밀번호 확인</label
      >
      <div class="custom_input">
        <input
          v-model="t_new_password"
          type="password"
          placeholder="새 비밀번호 확인"
        />
      </div>
      
    </div>

    <!-- <van-cell-group>
      <van-field v-model="old_password" label="이전 비밀번호" placeholder="이전 비밀번호를 입력하세요" />
      <van-field v-model="o_new_password" label="새 비밀번호" placeholder="새 비밀번호를 입력하세요" />
      <van-field v-model="t_new_password" label="새 비밀번호" placeholder="새 비밀번호를 다시 입력하세요" />
    </van-cell-group> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      o_new_password:"",
      t_new_password:"",
      old_password:"",
      userInfo:{}
    };
  },
  methods: {
    back(){
      return window.history.back();
    },
    save(){
      if(this.o_new_password === "" || this.o_new_password === null || this.o_new_password === undefined){
        this.$toast.fail("비밀번호를 입력하세요!");
        return false;
      }
      if(this.t_new_password === "" || this.t_new_password === null || this.t_new_password === undefined){
        this.$toast.fail("비밀번호를 입력하세요!");
        return false;
      }
      if(this.old_password === "" || this.old_password === null || this.old_password === undefined){
        this.$toast.fail("입력하세요!");
        return false;
      }
      if(this.o_new_password !== this.t_new_password){
        this.$toast("입력한 두 개의 비밀번호가 일치하지 않습니다.");
        return false;
      }
      this.$http({
        method: 'get',
        data:{
          old_password:this.old_password,
          new_password:this.o_new_password,
        },
        url: 'user_set_loginpw'
      }).then(res=>{
        if(res.code === 200){
          this.$toast(res.msg);
          setTimeout(() => {
            localStorage.clear()
            this.$router.push("Login")
          }, 500);

        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
.custom_input { 
  margin-bottom: 20px;
  background-color: transparent;
  border: 1px solid #ffcc99;
  border-radius: 6px;
  height: 68px;
  line-height: 68px;
  padding-left: 24px;
  color: #fff;
}
.custom_input input {
  background-color: transparent;
  border: none;
  outline: none;
}
.custom_input input::placeholder{
  font-size: 24px;
}


@import "../../assets/css/base.css";
.van-cell {
  font-size: 35px;
  line-height: 80px;
}
</style>
