<template>
  <div
    class="page"
    style="background-color: #1b1b1b !important; min-height: 100vh"
  >
    <div style="display: flex; align-items: center; padding: 14px">
      <div style="flex: 0 0 20%">
        <!-- 下拉， 暂时不需要，打开后，需要对接分类 -->
        <!-- <div
          style="
            padding-left: 10px;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            display: inline-block;
          "
        >
          서울
        </div>
        <van-icon
          name="arrow-down"
          :class="{ up: active, down: !active }"
          color="#FFF"
          @click="active ? (active = false) : (active = true)"
          style="display: inline-block"
        /> -->
      </div>
      <div style="flex: 1">
        <div
          style="
            font-size: 18px;
            color: #fff;
            font-weight: 700;
            text-align: center;
          "
        >
          출장만남
        </div>
      </div>
      <div style="flex: 0 0 20%"></div>
    </div>
    <div class="convention-item" style="padding-bottom: 140px">
      <!-- <van-tabs animated sticky line-width="100px" :swipeable="true">
        <van-tab title="The Beauty">
          <div class="card">
            The platform is a nationwide dating platform, connecting sexual
            partners. To ensure the privacy of each user, customers can only
            participate by contacting the front desk or displaying the real
            names of senior members of the platform.
          </div> -->

      <div
        v-for="(v, key) in list"
        :key="key"
        style="padding: 20px; padding-bottom: 0"
      >
        <div style="display: flex; align-items: center" @click="addgo(v)">
          <div
            style="
              border: 1px solid #ffcc99;
              border-radius: 6px;
              background-position: 0 0;
              background-repeat: no-repeat;
              background-size: 100%;
              width: 100px;
              height: 100px;
            "
            :style="{ backgroundImage: `url(${v.img_url[0]})` }"
          ></div>
          <div style="flex: 1">
            <div style="padding-left: 16px">
              <!-- <img src="../../assets/"/> -->
              <p class="vip">
                <span
                  style="padding-left: 24px; font-size: 16px; font-weight: 600"
                >
                  {{ v.xuanfei_name || v.name }}
                </span>
              </p>
            </div>
            <div
              style="
                padding-left: 16px;
                color: #fff;
                font-size: 13px;
                padding-bottom: 6px;
              "
            >
              {{
                v.nl +
                `세 ` +
                v.shengao +
                ` ` +
                v.tizhong +
                ` ` +
                v.chicun +
                `컵 사이즈 `
              }}
              {{ v.city + ` ` + v.area }}
            </div>
            <div style="padding-left: 16px">
              <div
                style="
                  background-color: #ffcc99;
                  color: #1b1b1b;
                  border-radius: 4px;
                  text-align: center;
                  padding: 2px 0;
                  line-height: 1.4;
                "
              >
                대화 하기
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="address">
            <van-row
              type="flex"
              justify=""
              gutter="65"
              v-for="(v, key) in list"
              :key="key"
            >
              <van-col span="" offset="" @click="addgo(v)" style="width: 100%">
                <p class="addname">{{ v.text }}</p>
                <img style="width: 100%; height: 150px" :src="v.img" alt="" />
              </van-col>
            </van-row>
          </div> -->
      <!-- </van-tab>
        <van-tab title="history">
          <div class="card">
            This platform is peripheral+business escort+passion In the same
            city, across the entire network, to ensure Privacy rights for each
            user, customers can only participate By contacting the Receptionist
            or introducing the real name of the city Senior members of the
            foundation.
          </div>
          <div class="rig-box">
            <p class="rig-title">What resources are available?</p>
            <p class="rig-content">
              Online celebrities, models, flight attendants, people Young
              people, college students, only things you can't think of You can't
              do it without this platform.
            </p>
            <p class="rig-title">service area?</p>
            <p class="rig-content">
              Free sex appointments in the same city, anywhere in the country,
              local first and second tier cities in Vietnam, and third tier
              cities can also be arranged by contacting the reception desk.
            </p>
            <p class="rig-content">
              The platform is a nationwide dating platform, connecting sexual
              partners. To ensure the privacy of each user, customers can only
              participate by contacting the front desk or displaying the real
              names of senior members of the platform.
            </p>
          </div>
        </van-tab>
      </van-tabs> -->
    </div>

    <!-- 下拉弹出 -->
    <van-popup v-model="active" position="top" :style="{ height: '70%' }">
      <div
        class="wrapper"
        style="background-color: #1b1b1b !important; min-height: 100vh"
      >
        <van-nav-bar :title="``" class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="handleClose()" />
          </template>
        </van-nav-bar>
        <div style="padding: 40px">111111</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
// import p1 from "../../assets/img/1.png";
// import p2 from "../../assets/img/2.png";
// import p3 from "../../assets/img/3.png";
// import p4 from "../../assets/img/4.png";
// import p5 from "../../assets/img/5.png";
// import p6 from "../../assets/img/6.png";
// import p7 from "../../assets/img/7.png";
// import p8 from "../../assets/img/8.png";
// import p9 from "../../assets/img/9.png";
// import p10 from "../../assets/img/10.png";
// import p11 from "../../assets/img/11.png";
// import p12 from "../../assets/img/12.png";
// import p13 from "../../assets/img/13.png";
export default {
  data() {
    return {
      active: false,
      list: [],

      // list: [
      //   {
      //     img: p1,
      //     text: "A001",
      //     id: 1,
      //   },
      //   {
      //     img: p2,
      //     text: "B001",
      //     id: 2,
      //   },
      //   {
      //     img: p3,
      //     text: "C001",
      //     id: 3,
      //   },
      //   {
      //     img: p4,
      //     text: "D001",
      //     id: 4,
      //   },
      //   {
      //     img: p5,
      //     text: "E001",
      //     id: 5,
      //   },
      //   {
      //     img: p6,
      //     text: "F001",
      //     id: 6,
      //   },
      //   {
      //     img: p7,
      //     text: "G001",
      //     id: 7,
      //   },
      //   {
      //     img: p8,
      //     text: "H001",
      //     id: 8,
      //   },
      //   {
      //     img: p9,
      //     text: "I001",
      //     id: 9,
      //   },
      //   {
      //     img: p10,
      //     text: "J001",
      //     id: 10,
      //   },
      //   {
      //     img: p11,
      //     text: "K001",
      //     id: 11,
      //   },
      //   {
      //     img: p12,
      //     text: "L001",
      //     id: 12,
      //   },
      //   {
      //     img: p13,
      //     text: "M001",
      //     id: 13,
      //   },
      // ],
      // addlist: [
      //   {
      //     0: "Beijing",
      //     1: "Shanghai",
      //     2: "Guangzhou",
      //     3: "Shenzhen",
      //   },
      //   {
      //     0: "Nanjing",
      //     1: "School",
      //     2: "Chongqing",
      //     3: "Xi'an",
      //   },
      // ],
    };
  },
  methods: {
    handleClose() {
      this.active = false;
    },

    addgo(data) {
      this.$router.push({ path: "/profile?id=" + data.id });
      // if (!localStorage.getItem("token")) {
      //   this.$router.push({ path: "/Login" });
      // } else {
      //   this.$router.push({
      //     path: "/list?id=" + data.id + "&name=" + data.name,
      //   });
      // }
    },
    // getAddress() {
    //   this.$http({
    //     method: "get",
    //     url: "address_list",
    //   }).then((res) => {
    //     console.log(`getAddress`, res.data);
    //     this.list = res.data.flat();
    //     console.log(`getAddress`, this.list);

    //     // res.data.flat().forEach((item, index) => {
    //     //   console.log(item.id);
    //     //   this.getxuanfeilist(item.id, index);
    //     // });

    //     // this.addlist = res.data;
    //     // res["data"][0].forEach((item, index) => {
    //     //   this.getxuanfeilist(item.id, index);
    //     // });
    //   });
    // },
    getxuanfeilist(id, index) {
      this.$http({
        method: "get",
        url: "xuanfeilist",
        data: { id: 1 },
      }).then((res) => {
        console.log(`111`, res.data);
        res.data.forEach((item) => {
          item.img_url = JSON.parse(item.img_url);
        });
        this.list = res.data;
        // if (
        //   res.data.length > 0 &&
        //   res.data[0].class_id == this.list[index].id
        // ) {
        //   const temp = {
        //     ...res.data[0],
        //     ...this.list[index],
        //   };
        //   this.$set(this.list, index, temp);
        // }
        // // this.$set(this.addlist[0][index], "img", res.data);
        console.log(`getxuanfeilist:`, this.list);
      });
    },
  },
  created() {
    this.getxuanfeilist();
  },
};
</script>

<style lang="less" scoped>
.vip {
  font-size: 0.75rem;
  color: #fff;
  font-weight: 500;
  position: relative;
  display: inline-block;
  padding-left: 4.375rem;
}
.vip::before {
  content: "";
  display: block;
  width: 3.125rem;
  height: 1.0625rem;
  border-top-left-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAAAXNSR0IArs4c6QAAAWBJREFUOE+d1DFLw0AUB/D/K+qkm1/BwY9QRJAawcWpiNhacHFzEDcRmgsubuLgJqjgxYogOCmYdO0sQhEnB6kguIgurfYvKQRCvKRp33b3Hr97xz1OnLJFxIP4sl1vIrrtrFpFCGoQjMTKf4HusqiydSNAAcB4pODD1t5kuE5BQLCitH8uQbFTtpoApk2QU1qoQHgKIGfofNt2vYNgP4SeAUzFIVUqrIvkTv4BAAjsK+3thLkEiK+k7IrgzIiQx8r1N6K5JKgDyKgZwbVyvWI8l3Q1kwGQDXbe5tRVsz00RLAJjOWVvv00nZKtI/KF3dyMqt23zK0mv1q0/p0/nFWXfvCyidGvo28B8lXtPaYhaXMUTEqHXS6qi3q9H5IOkWu26+ssSDJEbtmuf5gVMUPknu361UEQA8QjW/ubgyIRaP6JkAelvZVhkBAK/qQ7tFtLptHPCgsB6Q1T72cYPv4A5f+jg+8hIOgAAAAASUVORK5CYII=),
    -webkit-linear-gradient(#c7ae96, #f7e8d1);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAYAAACdkl3yAAAAAXNSR0IArs4c6QAAAWBJREFUOE+d1DFLw0AUB/D/K+qkm1/BwY9QRJAawcWpiNhacHFzEDcRmgsubuLgJqjgxYogOCmYdO0sQhEnB6kguIgurfYvKQRCvKRp33b3Hr97xz1OnLJFxIP4sl1vIrrtrFpFCGoQjMTKf4HusqiydSNAAcB4pODD1t5kuE5BQLCitH8uQbFTtpoApk2QU1qoQHgKIGfofNt2vYNgP4SeAUzFIVUqrIvkTv4BAAjsK+3thLkEiK+k7IrgzIiQx8r1N6K5JKgDyKgZwbVyvWI8l3Q1kwGQDXbe5tRVsz00RLAJjOWVvv00nZKtI/KF3dyMqt23zK0mv1q0/p0/nFWXfvCyidGvo28B8lXtPaYhaXMUTEqHXS6qi3q9H5IOkWu26+ssSDJEbtmuf5gVMUPknu361UEQA8QjW/ubgyIRaP6JkAelvZVhkBAK/qQ7tFtLptHPCgsB6Q1T72cYPv4A5f+jg+8hIOgAAAAASUVORK5CYII=),
    linear-gradient(#c7ae96, #f7e8d1);
  background-repeat: no-repeat, no-repeat;
  background-size: 0.5625rem 0.59375rem, cover;
  background-position: 0.625rem center, 0 0;
  position: absolute;
  left: 0.75rem;
  top: 0.0625rem;
}

.vip::after {
  content: "\C778\C99D";
  font-weight: bold;
  font-family: PingFang SC;
  color: #a37458;
  font-size: 0.75rem;
  position: absolute;
  left: 2rem;
}

::v-deep .van-tab__text {
  color: #ffcc99;
}
::v-deep .van-tabs__line {
  background-color: #ffcc99 !important;
}
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #1b1b1b;
}
.nav-bar {
  // background: linear-gradient(90deg,#775fd9,#FFCC99);
  background-color: #1b1b1b;
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}
::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  background: #1b1b1b;
}
.addname {
  text-align: center;
  transform: translateY(30px);
}
::v-deep .van-tab {
  font-size: 30px;
  line-height: 100px;
  font-weight: bold;
}
::v-deep .van-tabs__nav {
  background-color: #1b1b1b !important;
}

::v-deep .van-tabs__line {
  background-color: #1b1b1b;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
.card {
  background-color: transparent;
  padding: 0.625rem;
  width: 95%;
  color: white;
  font-size: 1rem;
  margin: 0.625rem auto;
  line-height: 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid #ffcc99;
}
::v-deep .van-row--flex {
  // height: 80px;
  width: 100%;
  line-height: 80px;
}
::v-deep .van-col {
  // height: 80px;
  width: 100%;
}
.rig-box {
  width: 95%;
  margin: 0.625rem auto;
}
.rig-title {
  color: #ffcc99;
  font-size: 1.425rem;
}
.rig-content {
  font-size: 30px;
  color: #999;
  // margin-top: 10px;
}
::v-deep .van-col {
  color: #ffcc99;
}
.address {
  width: 100%;
  // margin: 0 auto;
  padding-bottom: 110px;
  box-sizing: border-box;
  background-color: #1b1b1b !important;
}
</style>
